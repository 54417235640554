import React, { type CSSProperties, type FC, type ReactNode } from 'react';

import { Html } from '@/components/content/html';
import { FaqSchema } from '@/components/head/seo/schemas/faq-schema';

interface RelatedFaqsProps {
    faqs: Graphql.Faqs;
    header: ReactNode;
    firstOpen?: boolean;
}

const wrapperStyle: CSSProperties = {
    background: 'url(/assets/faq/small-smile.svg) no-repeat 3% 18%, url(/assets/faq/smile.svg) no-repeat 95% 5%',
};

// TODO: Przenieść title i description w inne miejsce
export const RelatedFaqs: FC<RelatedFaqsProps> = ({ faqs, header, firstOpen }) => {
    return (
        <section style={wrapperStyle} className="mt-80">
            <div className="container d-grid g-16 max-md bg-primary">
                {header}

                {faqs.map((faq, id) => (
                    <details
                        key={id}
                        open={firstOpen && id === 0}
                        style={{ cursor: 'pointer' }}
                        className="faq bg-white-gray box relative border-primary"
                    >
                        <summary className="fs-20 full-link">
                            <h3 style={{ display: 'inline', paddingLeft: '2px' }}>{faq.question}</h3>
                        </summary>

                        <Html className="mt-16">{faq.answer.html}</Html>
                    </details>
                ))}
            </div>

            <FaqSchema render={faqs} />
        </section>
    );
};
