import React, { type FC } from 'react';

import { FiizyButton } from '@/components/calculators/fiizy/elements/fiizy-button';

export type LoanProps = GraphqlSelect.Home<'loanTitle' | 'loanDescription' | 'loanCtaText'>;

// FIXME: Poprawić
export const Loan: FC<LoanProps> = ({ loanTitle, loanDescription }) => {
    return (
        <aside style={{ textAlign: 'right' }} className="loan box bg-blue c-white mt-80 py-80">
            <div className="container">
                <h2 style={{ marginBottom: '4px' }} className="fs-48">
                    {loanTitle}
                </h2>
                {loanDescription && <p>{loanDescription}</p>}

                <FiizyButton style={{ margin: '32px 0 0 auto' }} />
            </div>
        </aside>
    );
};
