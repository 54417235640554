import React, { type FC } from 'react';

import { site } from '@/utils/site';

export const WebsiteSchema: FC = () => {
    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: site.url,
        name: site.name,
        inLanguage: site.lang.head,
    });

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />;
};
