import React, { type FC } from 'react';

import { FiizyShort } from '@/components/calculators/fiizy/fiizy-short';

export const Hero: FC = () => {
    return (
        <header id="forma" className="hero box relative">
            <div style={{ height: '100%' }} className="container d-flex ai-center">
                <FiizyShort headerAs="h1" icon />
            </div>
        </header>
    );
};
