import React, { type FC } from 'react';

import { removeHtml } from '@/utils/remove-html';

interface FaqSchemaProps {
    render: Graphql.Faqs;
}

export const FaqSchema: FC<FaqSchemaProps> = ({ render }) => {
    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: render.map((element) => ({
            '@type': 'Question',
            name: element.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: removeHtml(element.answer.html),
            },
        })),
    });

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />;
};
