import React, { type CSSProperties, type FC } from 'react';

import { Logo } from '@/components/icons/logo';

export type WhyOurCompanyProps = GraphqlSelect.Home<'whyUsTitle' | 'whyUsDescription' | 'whyUs'>;

const itemsStyle: CSSProperties = {
    gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
    gap: '24px',
    margin: 'clamp(24px, 3.25vw, 48px) 0',
};

const circleStyle: CSSProperties = {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
};

export const WhyOurCompany: FC<WhyOurCompanyProps> = ({ whyUs, whyUsTitle, whyUsDescription }) => {
    return (
        <section className="container max-xl" style={{ marginTop: 'clamp(32px, 5.75vw, 96px)' }}>
            <h2 className="fs-48 text-center" dangerouslySetInnerHTML={{ __html: whyUsTitle.raw }} />

            <div style={itemsStyle} className="d-grid">
                {whyUs.map((whyUs, id) => (
                    <div key={id} className="box bg-gray">
                        <strong style={{ gap: '9px' }} className="d-flex ai-center fs-24">
                            <span style={{ ...circleStyle, background: whyUs.color }} />

                            {whyUs.title}
                        </strong>

                        <p style={{ paddingLeft: '23px' }} className="fs-18 mt-16">
                            {whyUs.description}
                        </p>
                    </div>
                ))}
            </div>

            {whyUsDescription && (
                <div className="text-center">
                    <Logo />

                    <p style={{ marginTop: '4px', fontWeight: '500' }} className="fs-24">
                        {whyUsDescription}
                    </p>
                </div>
            )}
        </section>
    );
};
