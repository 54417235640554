import { graphql, type HeadFC, type PageProps } from 'gatsby';
import React, { type FC } from 'react';

import { AppHead } from '@/components/head';
import { Hreflang } from '@/components/head/seo/hreflang';
import { FinancialServiceSchema } from '@/components/head/seo/schemas/financial-service-schema';
import { WebsiteSchema } from '@/components/head/seo/schemas/website-schema';
import { RelatedFaqs } from '@/components/related/related-faqs';
import { Carousel, type CarouselProps } from '@/components/sections/home/carousel';
import { Hero } from '@/components/sections/home/hero';
import { Loan, type LoanProps } from '@/components/sections/home/loan';
import { OurWork, type OurWorkProps } from '@/components/sections/home/our-work';
import { WhyOurCompany, type WhyOurCompanyProps } from '@/components/sections/home/why-our-company';

interface HomePageProps {
    home: GraphqlSelect.Home<'id' | 'metaTitle' | 'metaDescription' | 'faqTitle' | 'faq'> &
        LoanProps &
        OurWorkProps &
        WhyOurCompanyProps;
    firms: { nodes: CarouselProps['render'] };
}

const HomePage: FC<PageProps<HomePageProps>> = ({ data: { home, firms } }) => {
    return (
        <>
            <Hero />
            <Carousel render={firms.nodes} />
            <OurWork workTitle={home.workTitle} work={home.work} />
            <WhyOurCompany whyUsTitle={home.whyUsTitle} whyUsDescription={home.whyUsDescription} whyUs={home.whyUs} />

            <RelatedFaqs
                header={
                    <h2
                        className="fs-48 text-center"
                        style={{ marginBottom: 'clamp(8px, 2vw, 26px)' }}
                        dangerouslySetInnerHTML={{ __html: home.faqTitle.raw }}
                    />
                }
                faqs={home.faq}
            />

            <Loan loanTitle={home.loanTitle} loanDescription={home.loanDescription} loanCtaText={home.loanCtaText} />
        </>
    );
};

export const Head: HeadFC<HomePageProps> = ({ data: { home }, location: { pathname } }) => {
    return (
        <AppHead
            index
            title={home.metaTitle}
            description={home.metaDescription}
            canonical={pathname}
            ogImage={{ id: home.id, type: 'pages' }}
        >
            <FinancialServiceSchema />
            <WebsiteSchema />
            <Hreflang />
        </AppHead>
    );
};

export const query = graphql`
    query HomePage {
        home {
            id
            metaTitle
            metaDescription

            loanTitle
            loanDescription
            loanCtaText

            faqTitle {
                raw
            }

            faq {
                question

                answer {
                    html
                }
            }

            workTitle {
                raw
            }

            work {
                title
                description
            }

            whyUsDescription

            whyUsTitle {
                raw
            }

            whyUs {
                color
                title
                description
            }
        }

        firms: allFirm(
            limit: 25
            filter: { uri: { uri: { ne: null } }, image: { id: { ne: null } }, visible: { eq: true } }
            sort: { priority: DESC }
        ) {
            nodes {
                ...FirmImage
                name

                image {
                    width
                }
            }
        }
    }
`;

export default HomePage;
