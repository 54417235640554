import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type CSSProperties, type FC } from 'react';

export interface CarouselProps {
    render: (GraphqlSelect.Firm<'name' | 'image'> & { image: { width: number } })[];
}

const carouselStyle: CSSProperties = {
    height: '132px',
    marginTop: 'clamp(12px, 3.1vw, 40px)',
    overflow: 'hidden',
};

const carouselContentStyle: CSSProperties = {
    position: 'absolute',
    inset: '0 auto 0 0',
    zIndex: '0',
    gap: '50px',
    animation: 'FirmCarousel',
    animationDirection: 'alternate',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
};

export const Carousel: FC<CarouselProps> = ({ render }) => {
    const carouselWidth =
        render.slice(0, Math.floor(render.length / 2.5)).reduce((result, current) => result - current.image.width, 0) +
        'px';

    const _carouselContentStyle = {
        ...carouselContentStyle,
        animationDuration: render.length * 2 + 's',
        ['--carousel-width']: carouselWidth,
    } as Record<string, string | number>;

    return (
        <div style={carouselStyle} className="box bg-gray relative">
            <div style={_carouselContentStyle} className="d-flex ai-center w-100">
                {render.map((element, id) =>
                    element.image?.remoteFile ? (
                        <div key={id} style={{ flex: '0 0 auto' }} className="box-logotype">
                            <GatsbyImage image={getImage(element.image.remoteFile)!} alt={element.name} />
                        </div>
                    ) : null,
                )}
            </div>
        </div>
    );
};
