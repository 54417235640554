import React, { type FC } from 'react';

import { cn } from '@/utils/class-name';

export type OurWorkProps = GraphqlSelect.Home<'workTitle' | 'work'>;

const icon = ['document', 'wallet', 'document'];
const background = ['bg-blue', 'bg-orange', 'bg-green'];

export const OurWork: FC<OurWorkProps> = ({ workTitle, work }) => {
    return (
        <section className="container max-xl mt-80">
            <h2 className="fs-48 text-center" dangerouslySetInnerHTML={{ __html: workTitle.raw }} />

            <div style={{ gap: '18px 24px', marginTop: 'clamp(24px, 3.25vw, 48px)' }} className="d-flex flex-wrap">
                {work.map((work, id) => (
                    <div
                        key={id}
                        style={{ flex: '1 1 260px' }}
                        className={cn('box c-white d-flex flex-column g-16', background.at(id % background.length))}
                    >
                        <div className="box bg-white" style={{ padding: '16px', maxWidth: 'fit-content' }}>
                            <img
                                loading="lazy"
                                width="62"
                                height="62"
                                src={`/assets/home/${icon.at(id % icon.length)}.svg`}
                                alt=""
                            />
                        </div>

                        <strong className="fs-24">{work.title}</strong>
                        <p>{work.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};
