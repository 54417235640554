import React, { type FC } from 'react';

import { site } from '@/utils/site';

export const FinancialServiceSchema: FC = () => {
    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'FinancialService',
        image: `${site.url}/assets/logo.svg`,
        priceRange: '$',
        name: site.name,
        legalName: site.name,
        email: 'contact@loando.ro',
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'România',
            addressLocality: 'București',
            streetAddress: 'Strada Dej nr. 65',
            postalCode: '012284',
        },
    });

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />;
};
